import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'

import theme from '../../util/theme'

const NavLink = styled.p`
  cursor: pointer;
  color: ${props => props.theme.colors[props.color]};
  font-weight: ${props => (props.isActive ? '900' : '600')};

  ${props =>
    props.variant === 'desktop' &&
    css`
      font-size: 1.4rem;
      line-height: 1.6rem;
    `}
  ${props =>
    props.variant === 'mobile' &&
    css`
      font-size: 2.5rem;
      line-height: 4rem;
    `}
`

NavLink.propTypes = {
  isActive: PropTypes.bool,
  color: PropTypes.oneOf(Object.keys(theme.colors)).isRequired,
  variant: PropTypes.oneOf(['desktop', 'mobile']),
}

NavLink.defaultProps = {
  isActive: false,
  variant: 'desktop',
}

export default NavLink
