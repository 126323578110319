import styled from 'styled-components'

const AbsoluteIllustration = styled.img`
  position: absolute;
`

const RelativeIllustration = styled.img`
  position: relative;
`

/**
 * BEER LOVER
 */

const BeerLoverTilesLeftWrapper = styled(AbsoluteIllustration)`
  top: 0;
  left: 0;
  ${props => props.theme.bp.md(`width: 400px;`)}
  ${props => props.theme.bp.sm(`width: 300px;`)}
`
export const BeerLoverTilesLeft = () => (
  <BeerLoverTilesLeftWrapper src="/images/illustrations/beer-lover-tiles-left.png" />
)

const BeerLoverTilesRightWrapper = styled(AbsoluteIllustration)`
  bottom: 0;
  right: 0;
  ${props => props.theme.bp.md(`width: 400px;`)}
  ${props => props.theme.bp.sm(`width: 300px;`)}
`
export const BeerLoverTilesRight = () => (
  <BeerLoverTilesRightWrapper src="/images/illustrations/beer-lover-tiles-right.png" />
)

const BeerLoverTableWrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 150px;
  ${props => props.theme.bp.md(`height: 200px;`)}
  ${props =>
    props.theme.bp.sm(
      `height: 100px;`,
    )}
  background-image: url('/images/illustrations/beer-lover-table.svg');
  background-size: stretch;
`
export const BeerLoverTable = () => <BeerLoverTableWrapper />

const BeerLoverRedeemWrapper = styled(RelativeIllustration)`
  align-self: flex-end;
  bottom: 0;
  ${props =>
    props.theme.bp.xl(`
    width: 800px;
    top: 100px;
  `)}
  ${props =>
    props.theme.bp.lg(`
    width: 700px;
    top: 100px;
  `)}
  ${props =>
    props.theme.bp.md(`
    width: 100%;
    top: 60px;
  `)}
  ${props =>
    props.theme.bp.sm(`
    width: 100%;
    top: 60px;
  `)}
`
export const BeerLoverRedeem = () => (
  <BeerLoverRedeemWrapper src="/images/illustrations/beer-lover-redeem.svg" />
)

const BeerLoverSixPackWrapper = styled(RelativeIllustration)`
  width: 117px;
`
export const BeerLoverSixPack = () => (
  <BeerLoverSixPackWrapper src="/images/illustrations/beer-lover-six-pack.svg" />
)

const BeerLoverCirclesWrapper = styled(AbsoluteIllustration)`
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`
export const BeerLoverCircles = () => (
  <BeerLoverCirclesWrapper src="/images/illustrations/beer-lover-circles.svg" />
)

const BeerLoverDashboardPreviewWrapper = styled(RelativeIllustration)`
  ${props => props.theme.bp.md(`width: 70%;`)}
`
export const BeerLoverDashboardPreview = () => (
  <BeerLoverDashboardPreviewWrapper src="/images/illustrations/beer-lover-dashboard-preview.png" />
)

const BeerLoverSlatsWrapper = styled(AbsoluteIllustration)`
  top: 0;
  left: 0;
  height: 100%;
  ${props => props.theme.bp.md(`height: 50%;`)}
  ${props => props.theme.bp.sm(`height: 50%;`)}
`
export const BeerLoverSlats = () => (
  <BeerLoverSlatsWrapper src="/images/illustrations/beer-lover-slats.svg" />
)

const BeerLoverMerchWrapper = styled(RelativeIllustration)`
  margin: 0 40px 100px;
  ${props => props.theme.bp.md(`width: 70%;`)}
  ${props =>
    props.theme.bp.sm(`
    margin: 0 40px 30px;
  `)}
`
export const BeerLoverMerch = () => (
  <BeerLoverMerchWrapper src="/images/illustrations/beer-lover-merch.png" />
)

/**
 * BREWER
 */

const BrewerSlatsLeftWrapper = styled(AbsoluteIllustration)`
  top: 0;
  left: 0;
  ${props =>
    props.theme.bp.md(`
    padding-top: 150px;
    width: 400px;
  `)}
  ${props => props.theme.bp.sm(`width: 300px;`)}
`
export const BrewerSlatsLeft = () => (
  <BrewerSlatsLeftWrapper src="/images/illustrations/brewer-slats-left.png" />
)

const BrewerSlatsRightWrapper = styled(AbsoluteIllustration)`
  bottom: 0;
  right: 0;
  ${props =>
    props.theme.bp.md(`
    padding-bottom: 150px;
    width: 400px;
  `)}
  ${props => props.theme.bp.sm(`width: 300px;`)}
`
export const BrewerSlatsRight = () => (
  <BrewerSlatsRightWrapper src="/images/illustrations/brewer-slats-right.png" />
)
const BrewerTableWrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 150px;
  ${props => props.theme.bp.md(`height: 200px;`)}
  ${props =>
    props.theme.bp.sm(
      `height: 100px;`,
    )}
  background-image: url('/images/illustrations/brewer-table.svg');
  background-size: stretch;
`
export const BrewerTable = () => <BrewerTableWrapper />

const BrewerBartenderWrapper = styled(RelativeIllustration)`
  align-self: flex-end;
  ${props =>
    props.theme.bp.xl(`
    top: 20px;
  `)}
  ${props =>
    props.theme.bp.md(`
    top: -60px;
  `)}
`
export const BrewerBartender = () => (
  <BrewerBartenderWrapper src="/images/illustrations/brewer-bartender.svg" />
)

const BrewerDashboardPreviewWrapper = styled(RelativeIllustration)`
  left: 100px;
  margin: 40px 40px 100px;
  ${props =>
    props.theme.bp.lg(`
    left: 0px;
    width: 700px;
  `)}
  ${props =>
    props.theme.bp.md(`
    left: auto;
    width: 100%;
    margin: 0;
  `)}
  ${props =>
    props.theme.bp.sm(`
    left: auto;
    width: 100%;
    margin: 0;
  `)}
`
export const BrewerDashboardPreview = () => (
  <BrewerDashboardPreviewWrapper src="/images/illustrations/brewer-dashboard-preview.png" />
)

const BrewerBlobsWrapper = styled(AbsoluteIllustration)`
  top: 0;
  right: 0;
  width: 50%;
  height: 100%;

  ${props => props.theme.bp.lg(`width: 100%;`)}
`
export const BrewerBlobs = () => (
  <BrewerBlobsWrapper src="/images/illustrations/brewer-blobs.svg" />
)

const BrewerCheersWrapper = styled(RelativeIllustration)`
  margin: 60px 100px 60px 0;
  ${props =>
    props.theme.bp.md(`
    margin: 60px;
    width: 70%;
  `)}
  ${props =>
    props.theme.bp.sm(`
    margin: 0;
  `)}
`
export const BrewerCheers = () => (
  <BrewerCheersWrapper src="/images/illustrations/brewer-cheers.png" />
)

/**
 * SHARED
 */

const DotsWrapper = styled(AbsoluteIllustration)`
  top: 5%;
  right: 0;
  height: 100%;
  ${props => props.theme.bp.lg(`height: 75%;`)}
  ${props => props.theme.bp.md(`height: 50%;`)}
  ${props => props.theme.bp.sm(`height: 50%;`)}
`
export const Dots = () => <DotsWrapper src="/images/illustrations/dots.svg" />
