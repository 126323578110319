import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import theme from '../../util/theme'

export const SectionWrapper = styled.div`
  position: relative;
  width: 100%;
  background-color: ${props => props.theme.colors[props.backgroundColor]};
  overflow: hidden;
`
SectionWrapper.propTypes = {
  backgroundColor: PropTypes.oneOf(Object.keys(theme.colors)).isRequired,
}

export const SectionContainer = styled.div`
  position: relative;
  margin: 0 auto;
  display: flex;
  align-items: center;
  padding: 60px 30px;

  > * {
    width: 50%;
  }

  ${props =>
    props.theme.bp.xl(`
    max-width: 1400px;
  `)}
  ${props =>
    props.theme.bp.lg(`
    max-width: 1250px;
  `)}
  ${props =>
    props.theme.bp.md(`
    max-width: 1000px;
    text-align: center;
    flex-direction: ${props.flexDirection};
    > * { width: 100%; }
  `)}
  ${props =>
    props.theme.bp.sm(`
    max-width: 350px;
    text-align: center;
    flex-direction: ${props.flexDirection};
    > * { width: 100%; }
  `)}
`
SectionContainer.propTypes = {
  /**
   * To force elements to stack vertically at md/sm bp,
   * add a `flexDirection` prop that specifies the order:
   */
  flexDirection: PropTypes.oneOf(['column', 'column-reverse']),
}
SectionContainer.defaultProps = {
  flexDirection: 'column',
}
export const HeroSectionContainer = styled(SectionContainer)`
  padding-top: 80px;
  padding-bottom: 80px;
  ${props =>
    props.theme.bp.md(`
    padding-top: 60px;
    padding-bottom: 60px;
  `)}
  ${props =>
    props.theme.bp.sm(`
    padding-top: 60px;
    padding-bottom: 60px;
  `)}
`
HeroSectionContainer.propTypes = {
  /**
   * To force elements to stack vertically at md/sm bp,
   * add a `flexDirection` prop that specifies the order:
   */
  flexDirection: PropTypes.oneOf(['column', 'column-reverse']),
}
HeroSectionContainer.defaultProps = {
  flexDirection: 'column',
}

const NewsletterSectionWrapper = styled.div`
  width: 100%;
  background-color: ${props => props.theme.colors.primary1};
  padding: 80px;

  ${props => props.theme.bp.md(`padding: 80px 40px;`)}
  ${props => props.theme.bp.sm(`padding: 80px 20px;`)}
`
const NewsletterSectionContainer = styled.div`
  max-width: ${props => props.maxWidth}px;
  margin: 0 auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
`
export const NewsletterSection = ({ maxWidth, children }) => (
  <NewsletterSectionWrapper>
    <NewsletterSectionContainer maxWidth={maxWidth}>
      {children}
    </NewsletterSectionContainer>
  </NewsletterSectionWrapper>
)
NewsletterSection.propTypes = {
  maxWidth: PropTypes.number.isRequired,
}
