import styled from 'styled-components'

const CheersIcon = styled.div`
  width: 150px;
  height: 150px;
  background-image: url('/images/illustrations/cheers.svg');
  background-repeat: no-repeat;
  background-size: contain;
`

export default CheersIcon
