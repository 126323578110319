export default function getShadow({
  bottom = true,
  bottomShadowColor,
  top = false,
  topShadowColor,
} = {}) {
  const shadowsList = []
  if (bottom) shadowsList.push(`inset 0px -2px 0px 0px ${bottomShadowColor}`)
  if (top) shadowsList.push(`inset 0px 2px 0px 0px ${topShadowColor}`)
  return shadowsList.join(',')
}
