import styled from 'styled-components'
import PropTypes from 'prop-types'
import theme from '../../util/theme'

const Wrapper = styled.div`
  padding: 30px 0;
`

const Header = styled.p``

const BoldText = styled.span`
  font-family: 'Nunito Sans';
  font-weight: 900;
  color: ${props => props.theme.colors[props.color]};

  ${props =>
    props.variant === 'banner' &&
    props.theme.bp.xl(`
    font-size: 40px;
    line-height: 48px;
  `)}
  ${props =>
    props.variant === 'banner' &&
    props.theme.bp.lg(`
    font-size: 40px;
    line-height: 48px;
  `)}
  ${props =>
    props.variant === 'banner' &&
    props.theme.bp.md(`
    font-size: 35px;
    line-height: 45px;
  `)}
  ${props =>
    props.variant === 'banner' &&
    props.theme.bp.sm(`
    font-size: 28px;
    line-height: 33px;
  `)}

  ${props =>
    props.variant === 'section' &&
    props.theme.bp.xl(`
    font-size: 30px;
    line-height: 35px;
  `)}
  ${props =>
    props.variant === 'section' &&
    props.theme.bp.lg(`
    font-size: 30px;
    line-height: 35px;
  `)}
  ${props =>
    props.variant === 'section' &&
    props.theme.bp.md(`
    font-size: 26px;
    line-height: 30px;
  `)}
  ${props =>
    props.variant === 'section' &&
    props.theme.bp.sm(`
    font-size: 22px;
    line-height: 28px;
  `)}
`

const RegularText = styled.span`
  font-family: 'DM Serif Display';
  letter-spacing: 0.1rem;
  font-size: 4.1rem;
  line-height: 5.3rem;
  color: ${props => props.theme.colors[props.color]};
  ${props =>
    props.variant === 'banner' &&
    props.theme.bp.xl(`
    font-size: 40px;
    line-height: 48px;
  `)}
  ${props =>
    props.variant === 'banner' &&
    props.theme.bp.lg(`
    font-size: 40px;
    line-height: 48px;
  `)}
  ${props =>
    props.variant === 'banner' &&
    props.theme.bp.md(`
    font-size: 35px;
    line-height: 45px;
  `)}
  ${props =>
    props.variant === 'banner' &&
    props.theme.bp.sm(`
    font-size: 28px;
    line-height: 33px;
  `)}

  ${props =>
    props.variant === 'section' &&
    props.theme.bp.xl(`
    font-size: 30px;
    line-height: 35px;
  `)}
  ${props =>
    props.variant === 'section' &&
    props.theme.bp.lg(`
    font-size: 30px;
    line-height: 35px;
  `)}
  ${props =>
    props.variant === 'section' &&
    props.theme.bp.md(`
    font-size: 26px;
    line-height: 30px;
  `)}
  ${props =>
    props.variant === 'section' &&
    props.theme.bp.sm(`
    font-size: 22px;
    line-height: 28px;
  `)}
`

const BodyText = styled.p`
  font-family: 'Nunito Sans';
  font-weight: 600;
  font-size: 2.5rem;
  line-height: 3rem;
  color: ${props => props.theme.colors[props.color]};
  padding-top: 20px;

  font-size: 20px;
  line-height: 28px;

  ${props =>
    props.variant === 'section' &&
    props.theme.bp.sm(`
    font-size: 18px;
    line-height: 24px;
  `)}
`

export const MultiText = ({
  boldText,
  regularText,
  bodyText,
  color,
  separateWith,
  variant,
}) => (
  <Wrapper>
    <Header>
      {boldText && (
        <BoldText color={color} variant={variant}>
          {boldText}
          {separateWith === 'space' ? ' ' : <br />}
        </BoldText>
      )}
      {regularText && (
        <RegularText color={color} variant={variant}>
          {regularText}
        </RegularText>
      )}
    </Header>
    {bodyText && <BodyText color={color}>{bodyText}</BodyText>}
  </Wrapper>
)

MultiText.propTypes = {
  boldText: PropTypes.string.isRequired,
  regularText: PropTypes.string.isRequired,
  bodyText: PropTypes.string,
  color: PropTypes.oneOf(Object.keys(theme.colors)),
  separateWith: PropTypes.oneOf(['space', 'newline']),
  variant: PropTypes.oneOf(['banner', 'section']).isRequired,
}

MultiText.defaultProps = {
  boldText: null,
  regularText: null,
  bodyText: null,
  color: 'neutral4',
  separateWith: 'space',
}

export default MultiText
