import styled from 'styled-components'
import PropTypes from 'prop-types'
import getShadow from '../../util/getShadow'

const Fieldset = styled.fieldset`
  position: relative;
  padding: 0;
  border: none;
`

const Label = styled.label`
  ${props => props.theme.fonts.NunitoSans}
  font-weight: 600;
  color: ${props =>
    props.theme.colors[props.variant === 'dark' ? 'neutral4' : 'secondary1']};
  font-size: 1.2rem;
  line-height: 1.8rem;
  padding: 0 5px 5px;
  text-align: left;
  display: block;
`

const StyledInput = styled.input`
  border: 1px solid
    ${props =>
      props.theme.colors[props.variant === 'dark' ? 'primary3' : 'secondary1']};
  border-radius: 3px;
  padding: 20px;
  background-color: ${props =>
    props.theme.colors[props.variant === 'dark' ? 'primary5' : 'neutral4']};
  width: 100%;
  ${props => props.theme.fonts.NunitoSans}
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 2rem;
  color: ${props =>
    props.theme.colors[props.variant === 'dark' ? 'neutral4' : 'primary2']};
  appearance: none;

  ::placeholder {
    color: ${props => props.theme.colors.primary3};
  }

  &[type='submit'] {
    background-color: ${props => props.theme.colors.secondary2};
    border: none;
    border-radius: 25px;
    font-weight: 700;
    font-size: 1.5rem;
    color: ${props => props.theme.colors.primary2};
    letter-spacing: 0;
    text-align: center;
    line-height: 1.6rem;
    padding: 12px;
    box-shadow: inset 0px -2px 0px 0px #e1bc53;
    cursor: pointer;
    transition: background-color 300ms;

    &:hover {
      background-color: ${props => props.theme.colors.secondary5};
    }

    :disabled {
      background-color: ${props => props.theme.colors.neutral3};
      box-shadow: none;
      color: ${props => props.theme.colors.neutral1};
    }
  }
`

const Input = props => (
  <Fieldset>
    <Label htmlFor={props.name} variant={props.variant}>
      {props.label}
    </Label>
    <StyledInput {...props} variant={props.variant} />
  </Fieldset>
)

Input.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  variant: PropTypes.oneOf(['dark', 'light']),
}

Input.defaultProps = {
  name: '',
  label: '',
  variant: 'light',
}

export default Input
