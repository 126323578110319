import styled from 'styled-components'

const Wrapper = styled.div`
  width: 100%;
  background-color: ${props => props.theme.colors.primary2};
  padding: 40px 0;
`

const Container = styled.div`
  max-width: 530px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 60px auto;
  grid-column-gap: 25px;
  align-items: center;
  padding: 0 40px;
  justify-items: center;
  grid-row-gap: 40px;

  ${props =>
    props.theme.bp.sm(`
    grid-template-columns: 1fr;
  `)}
`

const Legal = styled.p`
  font-family: 'Nunito Sans';
  font-weight: 600;
  font-size: 1.3rem;
  line-height: 1.8rem;
  color: ${props => props.theme.colors.neutral4};
  padding-bottom: 10px;
`

const Copyright = styled.p`
  font-family: 'Nunito Sans';
  font-weight: 600;
  font-size: 1rem;
  line-height: 2.2rem;
  text-transform: uppercase;
  color: ${props => props.theme.colors.neutral4};
`

const MarketingFooter = () => (
  <Wrapper>
    <Container>
      <img src="/images/marketing-footer-logo.svg" />
      <div>
        <Legal>
          HOPCoin (the digital token) and HOPCoin.club (the website) are
          designed and administered by HOPCoin Services, LLC.
        </Legal>
        <Copyright>Copyright &copy; {new Date().getFullYear()}</Copyright>
      </div>
    </Container>
  </Wrapper>
)

export default MarketingFooter
