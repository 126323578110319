import React from 'react'
import { ThemeProvider } from 'styled-components'
import styled from 'styled-components'

import GlobalStyle from '../../util/GlobalStyle'
import theme from '../../util/theme'

const Wrapper = styled.div`
  position: absolute;
  height: 100vh;
  width: 100%;
  background-color: ${props => props.theme.colors.neutral4};
  padding-top: 80px;
`

const MarketingSiteLayout = ({ children }) => (
  <ThemeProvider theme={theme}>
    <GlobalStyle />
    <Wrapper>{children}</Wrapper>
  </ThemeProvider>
)

export default MarketingSiteLayout
