import styled from 'styled-components'
import PropTypes from 'prop-types'

const Logo = styled.div`
  background: url(/images/logo-${props => props.color}.svg);
  background-repeat: no-repeat;
  background-size: contain;
  width: 125px;
  height: 29px;
`

Logo.propTypes = {
  color: PropTypes.oneOf([`light`, `dark`]),
}

Logo.defaultProps = {
  color: `dark`,
}

export default Logo
