import styled, { css } from 'styled-components'
import { useState } from 'react'
import PropTypes from 'prop-types'
import Link from 'next/link'

import Logo from '../ui/Logo'
import NavLink from './NavLink'
import RoundedButton from '../ui/RoundedButton'

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  height: 80px;
  background-color: ${props => props.theme.colors.primary2};
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.175617);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 40px;
  z-index: 999;
`

const DesktopNav = styled.div`
  ${props => props.theme.bp.md(`display: none;`)}
  ${props => props.theme.bp.sm(`display: none;`)}
  display: flex;
  align-items: center;
  > * {
    margin-left: 40px;
  }
`

const A = styled.a`
  display: flex;
  text-decoration: none;
`

const Icon = styled.div`
  background-repeat: no-repeat;
  width: 24px;
  height: 24px;
  cursor: pointer;
  display: none;

  ${props => props.theme.bp.md(`display: block;`)}
  ${props => props.theme.bp.sm(`display: block;`)}
`
const HamburgerIcon = styled(Icon)`
  background-image: url(/images/menu-burger.svg);
`
const XIcon = styled(Icon)`
  background-image: url(/images/menu-x.svg);
`

const MobileNav = styled.div`
  background-color: ${props => props.theme.colors.primary2};
  position: absolute;
  top: 80px;
  left: 0;
  width: 100%;
  height: 100vh;
  transform: translateX(100%);
  transition: transform 400ms;
  padding: 0 0 40px 40px;
  display: none;

  > * {
    margin-top: 40px;
  }

  ${props =>
    props.showMobileNav &&
    css`
      transform: translateX(0%);
    `}

  ${props => props.theme.bp.md(`display: block;`)}
  ${props => props.theme.bp.sm(`display: block;`)}
`

const Menu = ({
  activePage,
  hidePrimaryCTA,
  handlePrimaryCTAClick,
  primaryCTAText,
}) => {
  const [showMobileNav, setShowMobileNav] = useState(false)

  const toggleMobileNav = () => setShowMobileNav(!showMobileNav)

  return (
    <Wrapper>
      <Logo color="light" />

      <DesktopNav>
        <Link href="/">
          <NavLink isActive={activePage === 'DRINKER'} color="neutral4">
            For Beer Lovers
          </NavLink>
        </Link>
        <Link href="/brewer-join">
          <NavLink isActive={activePage === 'BREWER'} color="neutral4">
            For Brewers
          </NavLink>
        </Link>
        <Link href="/faq">
          <NavLink isActive={activePage === 'FAQ'} color="neutral4">
            FAQ
          </NavLink>
        </Link>
        <A href="https://medium.com/hopcoin">
          <NavLink color="neutral4">
            Blog
          </NavLink>
        </A>
        <Link href="/login">
          <NavLink color="secondary2">Sign In</NavLink>
        </Link>
        {!hidePrimaryCTA && (
          <RoundedButton
            variant="primary"
            label={primaryCTAText}
            onClick={handlePrimaryCTAClick}
          />
        )}
      </DesktopNav>

      {showMobileNav && <XIcon onClick={toggleMobileNav} />}
      {!showMobileNav && <HamburgerIcon onClick={toggleMobileNav} />}

      <MobileNav showMobileNav={showMobileNav}>
        <Link href="/">
          <NavLink
            isActive={activePage === 'DRINKER'}
            color="neutral4"
            variant="mobile"
          >
            For Beer Lovers
          </NavLink>
        </Link>
        <Link href="/brewer-join">
          <NavLink
            isActive={activePage === 'BREWER'}
            color="neutral4"
            variant="mobile"
          >
            For Brewers
          </NavLink>
        </Link>
        <Link href="/faq">
          <NavLink
            isActive={activePage === 'FAQ'}
            color="neutral4"
            variant="mobile"
          >
            FAQ
          </NavLink>
        </Link>
        <A href="https://medium.com/hopcoin">
          <NavLink
            color="neutral4"
            variant="mobile"
          >
            Blog
          </NavLink>
        </A>
        <Link href="/login">
          <NavLink color="secondary2" variant="mobile">
            Sign In
          </NavLink>
        </Link>
        {!hidePrimaryCTA && (
          <NavLink
            onClick={() => {
              toggleMobileNav()
              handlePrimaryCTAClick()
            }}
            color="neutral4"
            variant="mobile"
          >
            {primaryCTAText}
          </NavLink>
        )}
      </MobileNav>
    </Wrapper>
  )
}

Menu.propTypes = {
  activePage: PropTypes.oneOf(['DRINKER', 'BREWER', 'FAQ']),
  handlePrimaryCTAClick: PropTypes.func,
  hidePrimaryCTA: PropTypes.bool,
  primaryCTAText: PropTypes.string,
}

Menu.defaultProps = {
  handlePrimaryCTAClick: () => {},
  hidePrimaryCTA: false,
  primaryCTAText: 'Join HOPCoin',
}

export default Menu
