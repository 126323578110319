import { useState } from 'react'
import styled from 'styled-components'

import Input from '../ui/Input'
import CheersIcon from '../ui/CheersIcon'

const ConfirmationWrapper = styled.div`
  text-align: center;
  width: 100%;
  display: grid;
  grid-row-gap: 40px;
  justify-items: center;
`

const ConfirmationText = styled.p`
  font-size: 20px;
  line-height: 26px;
  color: ${props => props.theme.colors.neutral3};
`

const NewsletterForm = ({ children }) => {
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [loading, setLoading] = useState(false)

  if (isSubmitted) {
    return (
      <ConfirmationWrapper>
        <CheersIcon />
        <ConfirmationText>
          Cheers! Thanks for submitting. <br />
          We will be in touch.
        </ConfirmationText>
      </ConfirmationWrapper>
    )
  }

  return (
    <form
      onSubmit={async e => {
        e.preventDefault()
        const data = new FormData(e.currentTarget)
        setLoading(true)
        await fetch('/subscribe', {
          method: 'POST',
          body: JSON.stringify(Object.fromEntries(data)),
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        })
        setLoading(false)
        setIsSubmitted(true)
      }}
    >
      {children}
      <Input
        variant="dark"
        type="submit"
        value={loading ? 'Loading' : 'Track the HOPCoin rollout'}
        disabled={isSubmitted}
      />
    </form>
  )
}

export default NewsletterForm
